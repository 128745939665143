/**
 *
 * CheckoutStep1Page
 *
 */

import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { compose, Dispatch } from 'redux';
import { Helmet } from 'react-helmet';
import translations from 'translations';
import messages from 'containers/CheckoutPage/messages';

import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import reducer from './reducer';
import saga from './saga';
import { RootState } from './types';
import CheckoutStep1 from 'components/Checkout/CheckoutStep1';
import { selectCart, selectIsFreeshipOffer, selectOrderDisabled } from 'containers/MainLayout/selectors';
import { push } from 'connected-react-router';
import { goToStep } from 'containers/CheckoutPage/actions';
import { removeAllProduct } from './actions';
import { setProductNote, updateProductQuantity } from 'containers/MainLayout/actions';
import { selectCheckout } from 'containers/CheckoutPage/selectors';
import { ICheckout } from 'containers/CheckoutPage/types';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Spin } from 'antd';
import { ProductNoteInput } from 'types/schema';
import selectCheckoutStep1Page from './selectors';

interface OwnProps {
  cart: any;
  checkout: ICheckout;
  orderDisabled: {
    orderDisabled: boolean;
    message: string;
  };
  isFreeshipOffer: boolean;
  checkoutstep1page: any;
}

interface StateProps {}

interface DispatchProps {
  onGoToDeliveryDetails: () => void;
  onCancelCheckout: () => void;
  onUpdateProductQuantity: (data: any) => void;
  onRemoveAllProduct: (data: any) => void;
  setProductNote: (data: ProductNoteInput) => void;
}

type Props = StateProps & DispatchProps & OwnProps & RouteComponentProps;

export class CheckoutStep1Page extends React.PureComponent<Props> {
  public onRemoveProduct = (data: any) => {
    this.props.onUpdateProductQuantity(data);
  };
  public onRemoveAllProduct = (data: any) => {
    this.props.onRemoveAllProduct(data);
  };
  public render() {
    return (
      <Fragment>
        <Helmet>
          <title>{translations(messages.headerStep1)}</title>
          <meta name="description" content={`${translations(messages.headerStep1)}`} />
        </Helmet>
        <Spin spinning={this.props.cart.loading}>
          <CheckoutStep1
            notAvailableProducts={this.props.cart.notAvailableProducts}
            onRemoveCartItem={this.onRemoveProduct}
            onRemoveCartAllItem={this.onRemoveAllProduct}
            onCancelCheckout={this.props.onCancelCheckout}
            onGoToDeliveryDetails={this.props.onGoToDeliveryDetails}
            checkout={this.props.checkout}
            cart={this.props.cart}
            orderDisabled={this.props.orderDisabled.orderDisabled}
            orderDisabledMessage={this.props.orderDisabled.message}
            isFreeshipOffer={this.props.isFreeshipOffer}
            setProductNote={this.props.setProductNote}
            noteItemLoading={this.props.checkoutstep1page?.noteItemLoading}
          />
        </Spin>
      </Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  checkoutstep1page: selectCheckoutStep1Page(),
  cart: selectCart(),
  checkout: selectCheckout(),
  orderDisabled: selectOrderDisabled(),
  isFreeshipOffer: selectIsFreeshipOffer(),
});

function mapDispatchToProps(dispatch: Dispatch, ownProps: OwnProps): DispatchProps {
  return {
    onGoToDeliveryDetails: () => dispatch(goToStep(2)),
    onCancelCheckout: () => dispatch(push('/market')),
    onUpdateProductQuantity: (data: any) => dispatch(updateProductQuantity({ ...data, quantity: 0 })),
    onRemoveAllProduct: (data: any) => dispatch(removeAllProduct(data)),
    setProductNote: (data) => dispatch(setProductNote(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withRouterComponent = withRouter(CheckoutStep1Page as any);

// <OwnProps> restricts access to the HOC's other props. This component must not do anything with reducer hoc
const withReducer = injectReducer<OwnProps>({
  key: 'checkoutStep1Page',
  reducer: reducer,
});
// <OwnProps> restricts access to the HOC's other props. This component must not do anything with saga hoc
const withSaga = injectSaga<OwnProps>({ key: 'checkoutStep1Page', saga: saga });

export default compose(withReducer, withSaga, withConnect)(withRouterComponent);

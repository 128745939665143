/*
 *
 * CheckoutStep1Page reducer
 *
 */

import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';
import MainActionTypes from 'containers/MainLayout/constants';

export const initialState: ContainerState = {
  default: null,
  noteItemLoading: false,
};

function checkoutStep1PageReducer(state: ContainerState = initialState, action: ContainerActions) {
  const { type } = action as any;

  switch (type) {
    case ActionTypes.DEFAULT_ACTION:
      return state;
    case MainActionTypes.SET_PRODUCT_NOTE_REQUEST:
      return {
        ...state,
        noteItemLoading: true,
      };
    case MainActionTypes.SET_PRODUCT_NOTE_SUCCESS:
      return {
        ...state,
        noteItemLoading: false,
      };
    case MainActionTypes.SET_PRODUCT_NOTE_FAILURE:
      return { ...state, noteItemLoading: false };
    default:
      return state;
  }
}

export default checkoutStep1PageReducer;

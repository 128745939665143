import ActionTypes from './constants';
import { ContainerState, ContainerActions } from './types';

export const initialState: ContainerState = {
  frequentlyBuyTogether: [],
};

function frequentlyBuyTogetherReducer(state: ContainerState = initialState, action: ContainerActions) {
  const { type, payload } = action as any;
  const frequentlyBuyTogetherData = JSON.parse(JSON.stringify(state.frequentlyBuyTogether));
  switch (type) {
    case ActionTypes.UPDATE_NOTE_TO_CART:
      return {
        ...state,
        frequentlyBuyTogether: frequentlyBuyTogetherData.map((item) => {
          if (item.id === payload.id) {
            return {
              ...item,
              note: payload.note,
            };
          }
          return item;
        }),
      };
    case ActionTypes.FAVORITE_SUCCESS:
      return {
        ...state,
        frequentlyBuyTogether: frequentlyBuyTogetherData.map((item) => {
          if (item.id === payload.id) {
            return {
              ...item,
              isFavorite: payload.favorite,
            };
          }
          return item;
        }),
      };
    case ActionTypes.SET_FREQUENTLY_BUY_TOGETHER_PRODUCT: {
      return { ...state, frequentlyBuyTogether: payload };
    }
    default:
      return state;
  }
}

export default frequentlyBuyTogetherReducer;

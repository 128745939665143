import { addFavorite, handleGetFrequentlyBuyTogether, removeFavorite, setProductNote } from '../../utils/apollo';
import { call, put, fork, take, all, select } from 'redux-saga/effects';
import { selectBuyer, selectCart } from '../../containers/MainLayout/selectors';
import {
  setFrequentlyBuyTogetherProduct,
  productNote as productNoteActions,
  favorite as favoriteActions,
  updateNoteToCard,
} from './actions';
import { message } from 'antd';
import translations from 'translations';
import utilMessages from '../../utils/messages';
import ActionTypes from './constants';
import { QueryGetFrequentlyBuyTogetherArgs } from '../../types/schema';
import MainActionTypes from 'containers/MainLayout/constants';
import { refreshCart } from 'containers/MainLayout/actions';

function* getFrequentlyBuyTogetherList() {
  while (true) {
    const { payload } = yield take(ActionTypes.FETCH_FREQUENTLY_BUY_TOGETHER_PRODUCT);
    yield put(setFrequentlyBuyTogetherProduct([]));
    let cart = yield select(selectCart());
    if (!cart?.cartSet?.length) {
      yield take(MainActionTypes.FETCH_CART_SUCCESS);
      cart = yield select(selectCart());
    }
    const buyer = yield select(selectBuyer());
    const skuCodes = cart?.cartSet?.[0]?.items?.map((item) => item.product.supplierInternalCode) || [];
    const params: QueryGetFrequentlyBuyTogetherArgs = {
      supplierInternalCodes: skuCodes || [],
      size: 50,
      regionCode: buyer?.region || '',
    };
    if (payload.supplierInternalCode) {
      params.requiredItemsBaseCodes = [payload.supplierInternalCode];
      params.supplierInternalCodes = skuCodes.includes(payload.supplierInternalCode)
        ? params.supplierInternalCodes
        : [...(params.supplierInternalCodes || []), payload.supplierInternalCode];
    }
    const response = yield call(handleGetFrequentlyBuyTogether, params);
    if (response?.products?.length) {
      yield put(setFrequentlyBuyTogetherProduct(response.products));
    } else {
      yield put(setFrequentlyBuyTogetherProduct([]));
    }
  }
}

function* setProductNoteFlow() {
  while (true) {
    const { payload } = yield take(ActionTypes.SET_PRODUCT_NOTE);
    yield put(productNoteActions.request());
    const response = yield call(setProductNote, { input: payload });
    if (!response.errors) {
      yield put(refreshCart());
      yield put(productNoteActions.success(response.product));
      yield put(updateNoteToCard(response.product));
      message.success(translations(utilMessages[payload.note ? 'addItemNoteSuccess' : 'deleteItemNoteSuccess']));
    } else {
      yield put(productNoteActions.failure(response.errors));
      message.error(translations(utilMessages[payload.note ? 'addItemNoteFailed' : 'deleteItemNoteFailed']));
    }
  }
}

function* favoriteFlow() {
  while (true) {
    const {
      payload: { product },
    } = yield take(ActionTypes.SET_FAVORITE);
    const id = product.id;
    yield put(favoriteActions.request());
    let response;
    if (product.isFavorite) {
      response = yield call(removeFavorite, { id: id });
    } else {
      response = yield call(addFavorite, { id: id });
    }
    if (response.errors) {
      yield put(favoriteActions.failure(response.errors));
    } else {
      yield put(favoriteActions.success({ id: id, favorite: !product.isFavorite, ...response }));
    }
  }
}

export default function* frequentlyBuyTogetherSaga() {
  const loggedIn = !!localStorage.getItem('token');
  if (loggedIn) {
    yield fork(setProductNoteFlow);
    yield fork(favoriteFlow);
    yield fork(getFrequentlyBuyTogetherList);
  }
}

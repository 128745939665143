import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the checkoutStep1Page state domain
 */

const frequentlyBuyTogetherDomain = (state: ApplicationRootState) => {
  return state ? state : initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by CheckoutStep1Page
 */

const selectFrequentlyBuyTogether = () =>
  createSelector(frequentlyBuyTogetherDomain, (substate) => {
    return substate;
  });

  const selectFrequentlyBuyTogetherData = () =>
    createSelector(frequentlyBuyTogetherDomain, (substate) => {
      return substate.frequentlyBuyTogether.frequentlyBuyTogether;
    });

export default selectFrequentlyBuyTogether;
export { frequentlyBuyTogetherDomain, selectFrequentlyBuyTogetherData };
import { createSelector } from 'reselect';
import { ApplicationRootState } from 'types';
import { initialState } from './reducer';

/**
 * Direct selector to the checkoutStep1Page state domain
 */

const selectCheckoutStep1PageDomain = (state: ApplicationRootState) => {
  return state ? state : initialState;
};

/**
 * Other specific selectors
 */

/**
 * Default selector used by CheckoutStep1Page
 */

const selectCheckoutStep1Page = () =>
  createSelector(selectCheckoutStep1PageDomain, (substate) => {
    return substate;
  });

export default selectCheckoutStep1Page;
export { selectCheckoutStep1PageDomain };

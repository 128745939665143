import injectSaga from 'utils/injectSaga';
import injectReducer from 'utils/injectReducer';
import { Col, Row } from 'antd';
import LoadingIndicator from 'components/LoadingIndicator';
import MarketItem from 'components/MarketItem/MarketItem';
import { Viewport } from 'components/ViewportProvider';
import messages from 'containers/CheckoutPage/messages';
import { setFavorite, setProductNote } from './actions';
import { selectAddedProducts, selectAddingProducts, selectUserPermissions } from 'containers/MainLayout/selectors';
import React, { FC, Suspense, useCallback, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { compose, Dispatch } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { Product, ProductNoteInput } from 'types/schema';
import ModalNote from '../../components/Checkout/ModalNote';
import reducer from './reducer';
import saga from './saga';
import { selectFrequentlyBuyTogetherData } from './selectors';
import { RootState } from './types';

const FrequentlyProducts = styled.div`
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.2);
  margin-top: 24px;
  padding: 24px;
  ${({noBackground}) => noBackground && `
    background: transparent;
    padding: 24px 0px 0px;
    box-shadow: none;
  `}
`;

const FrequentlyProductsTitle = styled.div`
  color: #424242;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 24px;
`;

const FrequentlyProductList = styled(Row)`
  @media(max-width: 1023px) {
    overflow: auto;
    padding: 1px 0 10px;
  }
`;

const FrequentlyProductItem = styled(Col)`
    @media(max-width: 768px) {
      flex: 0 0 auto !important;
    }
    @media (max-width: 768px) {
      & article {
        box-shadow: rgba(0, 0, 0, 0.25) 0px 0px 2px;
      }
    }
`;

interface IAddedProduct {
  [productId: string]: number;
}
interface IAddingProduct {
  [productId: string]: boolean;
}
interface IOwnProps {
    isProductDetail?: boolean
}
interface StateProps {
  frequentlyBuyTogetherProduct: any;
  addedProducts: IAddedProduct;
  addingProducts: IAddingProduct;
  permissions: string[];
}

interface DispatchProps {
  onToggleFavorite: (data: { product: Product }) => void;
  setProductNote: (data: ProductNoteInput) => void;
}

type IProps = StateProps & DispatchProps & IOwnProps;

const FrequentlyBuyTogether: FC<IProps> = (props) => {
  const {
    onToggleFavorite,
    setProductNote,
    // noteItemLoading,
    frequentlyBuyTogetherProduct,
    addedProducts,
    addingProducts,
    permissions,
    isProductDetail,
  } = props;
  const loggedIn = !!localStorage.getItem('token');
  const [previewProduct, setPreviewProduct] = useState<Product>();
  const [isOpenNoteModal, setIsOpenNoteModal] = useState<boolean>(false);

  const onClickOpenNoteModal = useCallback<(product: Product) => void>(
    (product) => {
      setPreviewProduct(product);
      setIsOpenNoteModal(true);
    },
    [setIsOpenNoteModal, setPreviewProduct],
  );

  const onClickDeleteNote = useCallback(
    (product) => {
      setProductNote({ productId: product.id, note: '' });
    },
    [setProductNote],
  );

  const onClickCloseNoteModal = useCallback(() => {
    setPreviewProduct(undefined);
    setIsOpenNoteModal(false);
  }, [setPreviewProduct, setIsOpenNoteModal]);

  const onSubmitAddingNote = useCallback(
    (note) => {
      if (previewProduct?.id) {
        setProductNote({ productId: previewProduct.id, note: note });
      }
      onClickCloseNoteModal();
    },
    [previewProduct, setProductNote, onClickCloseNoteModal],
  );

  return (
    <Viewport.Consumer>
      {({ width }) => {
        return (
          <>
            {!!frequentlyBuyTogetherProduct.length && <FrequentlyProducts noBackground={isProductDetail}>
              <FrequentlyProductsTitle>
                <FormattedMessage {...messages.frequentlyBuyTogetherTitle} />
              </FrequentlyProductsTitle>
              <FrequentlyProductList gutter={width > 992 ? [24, 24] : [16, 16]} wrap={width > 1024}>
                {frequentlyBuyTogetherProduct?.map((frequentlyBuyProduct, index) => (
                  <FrequentlyProductItem md={6} xxl={isProductDetail ? 4 : 6} key={frequentlyBuyProduct.id}>
                    <MarketItem
                      vertialOnly
                      listName="Frequently buy together product List"
                      loggedIn={loggedIn}
                      gotoSignIn={() => {}}
                      product={frequentlyBuyProduct}
                      loading={(frequentlyBuyProduct as any)?.loading}
                      maxQuantity={100}
                      favorite={frequentlyBuyProduct.isFavorite}
                      note={frequentlyBuyProduct.note}
                      added={addedProducts[frequentlyBuyProduct.id]}
                      adding={addingProducts[frequentlyBuyProduct.id]}
                      position={index}
                      onToggleFavorite={() => onToggleFavorite({ product: frequentlyBuyProduct })}
                      permissions={permissions}
                      onOpenNote={() => onClickOpenNoteModal(frequentlyBuyProduct)}
                      onDeleteNote={() => onClickDeleteNote(frequentlyBuyProduct)}
                      impressionProperties={{ type: '' as any }}
                      addToCartProperties={{ addFrom: '' as any }}
                    />
                  </FrequentlyProductItem>
                ))}
              </FrequentlyProductList>
            </FrequentlyProducts>}
            {isOpenNoteModal && (
              <Suspense fallback={<LoadingIndicator />}>
                <ModalNote
                  headerLabel={previewProduct?.name || ''}
                  isOpen={isOpenNoteModal}
                  confirmLoading={false}
                  onSubmit={onSubmitAddingNote}
                  onClose={onClickCloseNoteModal}
                  note={previewProduct?.note || ''}
                />
              </Suspense>
            )}
          </>
        );
      }}
    </Viewport.Consumer>
  );
};

const mapStateToProps = createStructuredSelector<RootState, StateProps>({
  frequentlyBuyTogetherProduct: selectFrequentlyBuyTogetherData(),
  addedProducts: selectAddedProducts(),
  addingProducts: selectAddingProducts(),
  permissions: selectUserPermissions(),
});

function mapDispatchToProps(dispatch: Dispatch): DispatchProps {
  return {
    setProductNote: (data) => dispatch(setProductNote(data)),
    onToggleFavorite: (data) => dispatch(setFavorite(data)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer<IOwnProps>({
  key: 'frequentlyBuyTogether',
  reducer: reducer,
});

const withSaga = injectSaga<StateProps>({ key: 'frequentlyBuyTogether', saga: saga });

export default compose(withReducer, withSaga, withConnect)(injectIntl(FrequentlyBuyTogether));
